import { Helmet } from 'react-helmet-async';
import gql from 'graphql-tag';
import { memoizeWith } from 'ramda';
import { useMetadata } from '../../context/MetadataContext';
import { useMetaTitle } from '../../hooks/useMetaTitle';
import { useMetaDescription } from '../../hooks/useMetaDescription';
import { useMetaKeywords } from '../../hooks/useMetaKeywords';
import { useTranslation } from '../../hooks/useTranslation';
import { useFrontendConfig } from '../../context/FrontendConfigContext';
import { useGlobalsContext } from '../../context/GlobalsContext';

const headerFragmentName = ({ type }) => `${type}PageHeaderMetadata`;
export const pageHeaderMetadataFragment = {
  name: headerFragmentName,
  fragment: memoizeWith(
    ({ type }) => type,
    ({ type }) => gql`
      fragment ${headerFragmentName({ type })} on ${type} {
        iso639Lang
      }
    `,
  ),
};

const metaFragmentName = ({ type }) => `${type}MetaData`;
export const metaDataFragment = {
  name: metaFragmentName,
  fragment: memoizeWith(
    ({ type }) => type,
    ({ type }) => gql`
      fragment ${metaFragmentName({ type })} on ${type} {
        name
        keywordStrings
        description
        teaser
        lastPublicationDate
        firstPublicationDate
        ${type === 'Navigation' ? 'pageTitle' : ''}
      }
    `,
  ),
};

// This is a hard coded, because it is not supposed to be environment dependent.
const canonicalOrigin = 'https://learngerman.dwelle-1.de';

export const PageMetaData = ({
  pageType,
  content = {},
  parentContentName,
  translationFirst = false,
  useOnlyCmsKeywords = false,
  useTeaserAsDescription = false,
  useDescriptionTranslation = false,
  addKeywordsAndDescription = true,
  relativeCanonicalUrl,
  noSearchEngine = false,
}) => {
  const {
    name: contentName,
    keywordStrings = [],
    description,
    teaser,
    lastPublicationDate,
    firstPublicationDate,
    pageTitle,
  } = content;
  const siteName = useTranslation('metadata.siteName');
  const iso639Lang = useMetadata();
  const { staticBaseHost } = useFrontendConfig();
  const title = useMetaTitle({ pageType, translationFirst, contentName, parentContentName });
  const descriptionMeta = useMetaDescription({
    pageType,
    contentName,
    teaser,
    description,
    useTeaserAsDescription,
    useDescriptionTranslation,
  });
  const keywordsString = useMetaKeywords({ pageType, useOnlyCmsKeywords, keywordStrings });
  const contentFullUrl = useGlobalsContext().window.location.href;
  const showDescription = addKeywordsAndDescription && descriptionMeta;

  return (
    <Helmet>
      <html lang={iso639Lang} />
      <title>{title}</title>
      <link rel="preconnect" href={staticBaseHost} />
      <link rel="dns-prefetch" href={staticBaseHost} />
      {relativeCanonicalUrl && (
        <link rel="canonical" href={`${canonicalOrigin}${relativeCanonicalUrl}`} />
      )}
      <meta
        name="copyright"
        content={`© ${new Date().getFullYear()} Deutsche Welle (www.dw.com)`}
      />
      {showDescription && <meta name="description" content={descriptionMeta} />}
      {showDescription && <meta property="og:description" content={descriptionMeta} />}
      {addKeywordsAndDescription && keywordsString && (
        <meta name="keywords" content={keywordsString} />
      )}
      <meta property="og:title" content={pageTitle || title} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:url" content={contentFullUrl} />
      <meta name="last-modified" content={lastPublicationDate} />
      <meta name="date" content={firstPublicationDate} />
      {noSearchEngine && <meta name="robots" content="none" />}
    </Helmet>
  );
};
